/* html {
  user-select: none;
} */

body {
  background: #efefef;
}

/* Navbar */
.navbar {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  /* padding-left: 20px;
  padding-right: 20px; */
}

.hamburgerButton:focus {
  outline: none;
  box-shadow: none;
  transition: 0.5s;
}

.hamburgerButton:hover {
  background: rgb(77, 228, 195);
  color: rgb(192, 129, 129);
}

/* Home css */
.cardName h1 {
  font-size: 5vw;
}
.cardName h4 {
  font-size: 2.5vw;
}

@media screen and (max-width: 768px) {
  .cardName h1 {
    font-size: 40px;
  }
  .cardName h4 {
    font-size: 25px;
  }
}

.iconDiv {
  display: flex;
  justify-content: center;
  align-items: center;
}

.iconDiv a {
  text-decoration: none;
  color: inherit;
  width: 80px;
  height: 80px;
  background: #f5f6fa;
  border-radius: 60%;
  margin: 10px;
  line-height: 92px;
  box-shadow: 1px 4px 2px 2px #9596997c;
}

.iconDiv a i {
  transition: all 0.3s linear;
}

.iconDiv a:hover i {
  transform: scale(1.4);
}

.fa-linkedin {
  color: #0097e6;
}
.fa-github {
  color: #171515;
}

.cardBox {
  border: 1.5px solid #cccaca;
  width: 65%;
  margin: auto;
}

.aboutMeDiv {
  list-style-type: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.aboutMeDiv button {
  text-transform: uppercase;
  --c: rgb(63, 231, 153);
  color: var(--c);
  font-size: 16px;
  border: 0.3em solid var(--c);
  border-radius: 0.5em;
  width: 12em;
  height: 3.5em;
  text-transform: uppercase;
  font-weight: bold;
  font-family: sans-serif;
  letter-spacing: 0.1em;
  text-align: center;
  line-height: 2.5em;
  position: relative;
  overflow: hidden;
  z-index: 1;
  transition: 0.2s;
  margin: 1em;
}

.aboutMeDiv button span {
  position: absolute;
  width: 25%;
  height: 100%;
  background-color: var(--c);
  transform: translateY(150%);
  border-radius: 50%;
  left: calc((var(--n) - 1) * 25%);
  transition: 0.5s;
  transition-delay: calc((var(--n) - 1) * 0.1s);
  z-index: -1;
}

.aboutMeDiv button:hover {
  color: black;
}

.aboutMeDiv button:hover span {
  transform: translateY(0) scale(2);
}

.aboutMeDiv button span:nth-child(1) {
  --n: 1;
}

.aboutMeDiv button span:nth-child(2) {
  --n: 2;
}

.aboutMeDiv button span:nth-child(3) {
  --n: 3;
}

.aboutMeDiv button span:nth-child(4) {
  --n: 4;
}

.avatarDiv {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatar {
  margin: 10px;
  width: 50%;
  height: 50%;
  border: 2px solid rgb(143, 125, 125);
  border-radius: 50%;
  margin: 10px;
  display: block;
}

.logo {
  position: absolute;
  width: 38px;
  height: 38px;
  margin: 10px;
}

.js {
  left: 10%;
  top: 10%;
}

.imageContainer {
  margin-top: 8%;
  /* margin-bottom: 20px; */
}

.mongodb {
  top: -17%;
  left: 35%;
  width: 100px;
}

.express {
  right: 5%;
  top: 10%;
  width: 76px;
}

.react {
  bottom: 7%;
  right: 10%;
}

.python {
  bottom: -20%;
  left: 45%;
}

.django {
  left: 10%;
  bottom: 10%;
}

@media screen and (max-width: 991px) and (min-width: 768px) {
  .imageContainer {
    margin-top: 15%;
  }
  .avatarDiv {
    margin: 0px auto;
    justify-content: end;
  }
  .avatar {
    width: 70%;
    height: 70%;
  }
  .express {
    right: -17%;
    top: 10%;
    width: 76px;
  }

  .react {
    bottom: 7%;
    right: -12%;
  }
  .python {
    bottom: -20%;
    left: 50%;
  }
  .mongodb {
    top: -17%;
    left: 38%;
    width: 100px;
  }
}

/* footer */
.footerDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: static;
  height: 25vh;
  background: #07090f;
}
.footerIconDiv {
  text-align: center;
  margin-bottom: 1%;
}

.footerIcon {
  display: inline-block;
  margin: 0px 10px;
  text-decoration: none;
  width: 50px;
  height: 50px;
  background: #fff;
  border-radius: 50%;
  line-height: 60px;
}

.footerEnd {
  /* text-decoration: none; */
  color: white;
}
