.portfolioDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.portfolioHeading {
  font-family: "Open Sans", sans-serif;
  margin-bottom: 10px;
  color: rgb(51, 132, 160);
  font-size: 7vw;
  text-align: center;
  line-height: 100%;
  font-weight: 900;
  letter-spacing: -1px;
  text-transform: uppercase;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 2px rgb(255, 81, 0);
}

.portfolioSubheading {
  font-size: 19px;
  /* font-weight: bold; */
  margin-bottom: 5px;
}

@media screen and (max-width: 750px) {
  .portfolioHeading {
    font-size: 40px;
  }
}

.portfolioContent {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.portfolioImageDiv {
  transform: translateY(0px);
  border-radius: 5px;
  transition: 0.3s;
  margin-bottom: 8px;
}
.portfolioImag {
  border-radius: 5px;
}
.portfolioContentInfo:hover {
  /* color: rgb(85, 214, 214); */
  color: #5f4b8bff;
  text-shadow: 2px 2px 20px 3px #5f4b8bff;
  cursor: pointer;
}
.portfolioImageDiv:hover {
  transform: translateY(-2.5px);
  box-shadow: 2px 2px 20px 3px #5f4b8bff;
}
.portfolioTags {
  padding: 2px 8px;
}
.portfolioTags:hover {
  background: #e2e2e2d3;
  cursor: pointer;
}
.linkTags {
  opacity: 0.5;
  color: black;
}
.linkTags:hover {
  opacity: 1;
  color: rgb(0, 217, 255);
}

.buttonBox a {
  margin-top: 4px;
  height: 50px;
  transform: translateY(-1.5px);
  border-bottom: 5px solid #5f4b8bff;
  border-radius: 5px;
  transition: 0.3s;
}

.buttonBox a:hover {
  transform: translateY(0px);
  border-bottom: 2px solid #5f4b8bff;
}

.imageBox img {
  border-radius: 10px;
}

.descriptionBox li {
  font-size: 1.3rem;
}

/* Portfolio description */
.backButton {
  background: grey;
  width: 155px;
  border-radius: 6px;
}
.backButton:hover {
  background: #000;
}

@media screen and (max-width: 991px) {
  .descriptionBox li {
    padding: 2px 1px;
    font-size: 1.1rem;
  }
}
@media screen and (max-width: 767px) {
  .descriptionBox li {
    font-size: 0.91rem;
  }
}
